import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/shared/layoutParallax"
import SEO from "../../components/SEO/SEOPage"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import {
  wrapper,
  color,
  featuredWrapper,
  noFeaturedWrapper,
  breakpoints,
  ContentStyles,
  ContentStylesExt,
} from "../../utils/style"
import Breadcrumbs from "../../components/shared/Breadcrumbs"
import GetWindowWidth from "../../components/shared/GetWindowWidth"

const WrapperDesktop = styled("div")`
  ${wrapper}
`

const FeaturedWrapper = styled("div")`
  ${featuredWrapper}
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  .top-wrap {
    position: absolute;
    top: 25%;
    left: 32px;
    right: 32px;
  }
  h1 {
    margin: 0 0 20px;
    font-weight: 800;
    font-size: 64px;
    line-height: 124.6%;
  }
  .subtitle {
    font-size: 16px;
    line-height: 24px;
    white-space: pre-wrap;
  }
  .gatsby-image-wrapper {
    border-radius: 24px;
    max-height: 448px;
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      background: ${color.transparentBlack};
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      border-radius: 28px;
    }
  }
  img {
    border-radius: 28px;
  }
  @media (max-width: ${breakpoints.md}) {
    .gatsby-image-wrapper {
      max-height: 100%;
    }
    h1 {
      font-size: 26px;
      line-height: 32px;
      margin: 0 0 16px;
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    .top-wrap {
      top: 30%;
      left: 16px;
      right: 16px;
    }
    h1 {
      font-size: 22px;
      line-height: 30px;
      margin: 0;
    }
  }
`

const NoFeaturedWrapper = styled("div")`
  ${noFeaturedWrapper}
`

const ContentWrapper = styled("div")`
  ${wrapper}
  ${ContentStyles}
  ${ContentStylesExt}
  padding: 0;
`

const BottomBanner = styled("div")`
  background: linear-gradient(
    128.46deg,
    rgba(169, 0, 0, 0.4) 25.23%,
    rgba(120, 0, 0, 0.1) 88.32%
  );
  padding: 32px 0;
  text-align: center;
  span {
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    color: ${color.gold};
  }
`

const BottomColumn = styled("div")`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .col {
    flex-basis: 48%;
    background: ${color.transparentWhite1};
    border-radius: 24px;
    padding: 16px;
    margin-bottom: 32px;
    margin-right: 1%;
  }
  @media (max-width: ${breakpoints.sm}) {
    .col {
      flex-basis: 100%;
      margin-right: 0;
      margin-bottom: 24px;
    }
  }
`

const PostContentWrapper = styled("div")`
  margin: 20px 0;
  .item-wrap {
    width: 48%;
    margin-bottom: 30px;
    background: ${color.transparentWhite1};
    padding: 24px 16px;
    border-radius: 24px;
  }
  .item-content-link {
    &:hover,
    &:active {
      text-decoration: none;
    }
  }
  .gatsby-image-wrapper {
    border-radius: 10px;
  }
  img {
    border-radius: 10px;
  }
  .img-wrap {
    margin-bottom: 10px;
    display: block;
  }

  .h3 {
    margin: 16px 0;
    display: block;
  }

  .excerpt {
    margin-bottom: 20px;
    max-height: 164px;
    overflow-y: hidden;
    color: ${color.white};
  }

  .button {
    width: 100%;
  }

  @media (max-width: ${breakpoints.sm}) {
    .item-wrap {
      width: 100%;
    }
    .excerpt {
      font-size: 14px;
      max-height: none;
    }
  }
`

const ImgWrap = styled("div")``

const PageTemplate = ({ data, pageContext }) => {
  const windowWidth = GetWindowWidth()
  const isDesktop = windowWidth > 600
  const currentPage = data.wordpressPage
  const breadcrumbs = pageContext.breadcrumbs
  const page_title = pageContext.page_title
  const page_subtitle = currentPage.acf.top_subtitle
  const featuredMedia = currentPage.featured_media
  const metaTitle =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_title
      ? currentPage.yoast_meta.yoast_wpseo_title
      : currentPage.title + "- " + process.env.OWNER
  const metaDesc =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_metadesc
      ? currentPage.yoast_meta.yoast_wpseo_metadesc
      : ""
  const content_field_one = currentPage.acf.content_field_01
  const content_field_two = currentPage.acf.content_field_02
  const content_field_tree = currentPage.acf.content_field_03
  const content_field_four = currentPage.acf.content_field_04
  const posts = data.allWordpressPost.edges
  const cta_footer = currentPage.acf.cta_footer
  const socialImage = currentPage.featured_media?.localFile?.publicURL

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        pathname={currentPage.path}
        breadcrumbs={breadcrumbs}
        socialImage={socialImage}
      />
      <WrapperDesktop>
        {featuredMedia &&
        featuredMedia.localFile &&
        featuredMedia.localFile.childImageSharp &&
        featuredMedia.localFile.childImageSharp.fluid &&
        isDesktop ? (
          <FeaturedWrapper>
            <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title} />
            <Img
              fluid={featuredMedia.localFile.childImageSharp.fluid}
              alt={
                currentPage.featured_media.alt_text
                  ? currentPage.featured_media.alt_text
                  : page_title
              }
              fadeIn={false}
              loading="eager"
            />
            <div className="top-wrap">
              <h1
                className="h1 rich-design"
                dangerouslySetInnerHTML={{ __html: page_title }}
              />
              {page_subtitle && (
                <span
                  className="subtitle"
                  dangerouslySetInnerHTML={{ __html: page_subtitle }}
                />
              )}
            </div>
          </FeaturedWrapper>
        ) : (
          <NoFeaturedWrapper>
            <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title} />
            <h1 dangerouslySetInnerHTML={{ __html: page_title }} />
            {page_subtitle && (
              <span dangerouslySetInnerHTML={{ __html: page_subtitle }} />
            )}
          </NoFeaturedWrapper>
        )}

        <PostContentWrapper className="flex">
          {posts.map(({ node }) => (
            <div className="item-wrap" key={node.slug}>
              <Link className="item-content-link" to={"/" + node.slug + "/"}>
                {node.featured_media &&
                node.featured_media.localFile &&
                node.featured_media.localFile.childImageSharp.fluid ? (
                  <div className="img-wrap">
                    <Img
                      fluid={
                        node.featured_media.localFile.childImageSharp.fluid
                      }
                      alt={
                        node.featured_media.alt_text
                          ? node.featured_media.alt_text
                          : node.title
                      }
                    />
                  </div>
                ) : (
                  <ImgWrap>
                    <Img
                      fluid={data.placeholder.childImageSharp.fluid}
                      alt={node.title}
                    />
                  </ImgWrap>
                )}
                <span className="h3">{node.title}</span>
                {/*<div className="excerpt" dangerouslySetInnerHTML={{ __html: node.excerpt }}/>*/}
                <span className="button transparent-button">อ่านเพิ่มเติม</span>
              </Link>
            </div>
          ))}
        </PostContentWrapper>

        <ContentWrapper>
          <div dangerouslySetInnerHTML={{ __html: currentPage.content }} />
          <BottomColumn>
            {content_field_one && (
              <div className="col">
                <span dangerouslySetInnerHTML={{ __html: content_field_one }} />
              </div>
            )}
            {content_field_two && (
              <div className="col">
                <span dangerouslySetInnerHTML={{ __html: content_field_two }} />
              </div>
            )}
            {content_field_tree && (
              <div className="col">
                <span
                  dangerouslySetInnerHTML={{ __html: content_field_tree }}
                />
              </div>
            )}
            {content_field_four && (
              <div className="col">
                <span
                  dangerouslySetInnerHTML={{ __html: content_field_four }}
                />
              </div>
            )}
          </BottomColumn>
        </ContentWrapper>
      </WrapperDesktop>
      {cta_footer && (
        <BottomBanner>
          <WrapperDesktop>
            <span dangerouslySetInnerHTML={{ __html: cta_footer }} />
          </WrapperDesktop>
        </BottomBanner>
      )}
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      wordpress_id
      title
      content
      date(formatString: "MMMM DD, YYYY")
      path
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        page_template
        top_subtitle
        cta_footer
        content_field_01
        content_field_02
        content_field_03
        content_field_04
      }
      featured_media {
        alt_text
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    allWordpressCategory(filter: { wordpress_id: { eq: 12 } }) {
      edges {
        node {
          id
          name
          slug
          description
          path
          link
          acf {
            category_description_top
            category_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1128) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          yoast_meta {
            yoast_wpseo_title
            yoast_wpseo_metadesc
          }
        }
      }
    }
    allWordpressPost(
      sort: { fields: date, order: DESC }
      filter: { categories: { elemMatch: { wordpress_id: { eq: 12 } } } }
    ) {
      edges {
        node {
          title
          excerpt
          slug
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    placeholder: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
